<template>
  <div class="position-relative">
    <v-btn
      v-if="!isNaN(accordeonPanel)"
      icon
      class="position-absolute mr-3"
      style="transform: translateX(-100%); left: -8px"
      @click="accordeonPanel = undefined"
      rounded
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-expansion-panels
      class="chat-bot"
      style="width: 300px"
      v-model="accordeonPanel"
    >
      <v-expansion-panel>
        <v-expansion-panel-header color="#3a3f5b" expand-icon="" class="pa-2">
          <div class="d-flex">
            <v-avatar size="40" class="mr-3">
              <v-img
                src="/img/profile-photo_placeholder.jpeg"
                alt="profile image"
              ></v-img>
            </v-avatar>
            <div class="d-flex flex-column justify-space-between">
              <span class="white--text">Tumar Chat Bot</span>
              <span class="grey--text">Answers to questions </span>
            </div>
            <v-btn
              v-if="!isNaN(accordeonPanel) && screenWidth < 980"
              icon
              class="position-absolute mr-3"
              style="right: 10px"
              @click.stop="$emit('closeChatbot')"
              rounded
            >
              <v-icon class="close-chat" fill="#fff">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="chat-bot__content">
          <div class="chat-window pa-4">
            <div class="chat-window__body d-flex flex-column">
              <ChatBotMessage
                v-for="(msg, index) in messages"
                :key="msg.id"
                :msg="msg"
                class="message d-flex align-end"
                :class="{
                  'align-self-end': msg.me,
                  'mb-3': index !== messages.length - 1,
                }"
                :id="`message-${msg.id}`"
              >
                <template v-if="msg.actions" #message_actions>
                  <div class="mt-2">
                    <v-btn
                      v-if="msg.actions.yes"
                      class="message__actions mr-2"
                      outlined
                      style="border-color: #2fae44"
                      text
                      color="#2fae44"
                      rounded
                      :ripple="false"
                      @click="msg.actions.yes"
                    >
                      {{ msg.actions.confirmText || 'YES' }}
                    </v-btn>
                    <v-btn
                      v-if="msg.actions.no"
                      class="message__actions"
                      outlined
                      style="border-color: #2fae44"
                      text
                      color="#2fae44"
                      rounded
                      :ripple="false"
                      @click="msg.actions.no"
                    >
                      {{ msg.actions.denyText || 'NO' }}
                    </v-btn>
                  </div>
                </template>
                <template v-if="msg.body.includes('Click')" #message_body>
                  <div>
                    {{ msg.body.split(' ').slice(0, 2).join(' ') }}
                    <a
                      href="#"
                      style="color: blue; text-decoration: underline"
                      @click.prevent="$router.push({ name: 'Contact us' })"
                    >
                      {{ msg.body.split(' ').slice(2).join('') }}
                    </a>
                  </div>
                </template>
              </ChatBotMessage>
              <!-- Поле ввода Email/WhatsApp -->
              <div v-if="showContactInput" class="mt-2 inputchatbot">
                <v-text-field
                  v-model="contact"
                  label="Email or WhatsApp"
                  class="mt-2"
                  @keyup.enter="sendContact"
                ></v-text-field>
                <v-btn color="success" class="mt-2" @click="sendContact"
                  >SEND ✉️</v-btn
                >
              </div>
            </div>
          </div>
          <div class="position-relative chat-input">
            <div
              v-show="questions.length && message.length >= 3"
              class="pa-4 position-absolute question-suggestions"
            >
              <v-chip
                v-for="q in questions"
                :key="q.id"
                @click="sendQuestion(q.question, q.answer)"
                class="my-1"
              >
                {{ q.question }}
              </v-chip>
            </div>
            <v-form
              class="ma-0 pa-4 d-flex"
              style="border-top: 1px solid #f2f2f2"
              @submit.prevent="sendMessage"
            >
              <v-text-field
                solo
                outlined
                hide-details
                dense
                v-model="message"
                class="mr-3"
                placeholder="Enter your message"
              ></v-text-field>
              <v-btn icon color="grey" type="submit" :disabled="!message">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
            </v-form>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('faq')
import ChatBotMessage from './Message.vue'
export default {
  name: 'ChatBot',
  components: {
    ChatBotMessage,
  },
  data() {
    return {
      messages: [],
      message: '',
      accordeonPanel: undefined,
      screenWidth: window.innerWidth,
      contact: '',
      showContactInput: false,
    }
  },
  computed: {
    ...mapState(['FAQs']),
    questions() {
      const items = this.FAQs.filter(
        (item) =>
          item.question.toLowerCase().includes(this.message.toLowerCase()) &&
          this.message
      )
      return items
    },
  },
  watch: {
    'messages.length'(val) {
      if (val && val - 2 >= 0) {
        const id = `message-${this.messages[val - 2].id}`
        const domEl = document.getElementById(id)
        setTimeout(() => {
          if (domEl) {
            domEl.scrollIntoView({ behavior: 'smooth' })
          }
        })
      }
    },
    showContactInput(val) {
    if (val) {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    }
  },
    screenWidth(val) {
      if (val < 980) {
        this.accordeonPanel = 0
      } else {
        this.accordeonPanel = undefined
      }
    },
    message(val) {
      if (val && val.length >= 3) {
        this.fetchFAQs()
      }
    },
    accordeonPanel(val) {
      if (!isNaN(val)) {
        if (!this.messages.length) {
          setTimeout(() => {
            this.sendWelcomeMessage()
          }, 1000)
        }
        this.$emit('showChatbot', true)
      } else {
        this.$emit('showChatbot', false)
      }
    },
  },
  methods: {
    scrollToBottom() {
    this.$nextTick(() => {
      const chatWindow = this.$el.querySelector('.chat-window');
      if (chatWindow) {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }
    });
  },
    sendContact() {
      if (this.contact) {
        const lastUserMessage = [...this.messages]
          .reverse()
          .find((item) => item.author === 'User')
        const name = 'Клиент с сайта'
        const email = this.contact
        const subject = 'Не получил ответа от Чат-бот'
        const message = lastUserMessage.body

        const data = { name, email, subject, message }
        this.createFeedback(data)
      this.createFAQ(lastUserMessage.body)

        this.messages.push({
          body: `Great, we have received your request, please expect a reply, we will be in touch with you very soon 🤗`,
          id: this.messages.length + 1,
          me: false,
          author: 'Operator',
        })
        this.contact = ''
        this.showContactInput = false
      }
    },
    ...mapActions(['fetchFAQs', 'createFAQ', 'createFeedback']),
    sendMessage() {
      const message = {
        me: true,
        body: this.message,
        id: this.messages.length + 1,
        author: 'User',
      }
      const messageToLower = this.message.toLowerCase()
      const matchQuestion =
        this.FAQs.find((q) => q.question.toLowerCase() === messageToLower) ||
        this.FAQs.filter((item) =>
          item.question.toLowerCase().includes(messageToLower)
        ).sort((o1, o2) => o1.question.length - o2.question.length)[0]
      this.messages.push(message)
      this.message = ''
      if (!matchQuestion) {
        setTimeout(() => {
          const text =
            "Unfortunately the bot can't help you yet 🥹 Please leave your Email or WhatsApp address for feedback 👇"

          const newMessage = this.generateNewMessage(text)

          this.messages.push({
            ...newMessage,
          })
          this.showContactInput = true
        }, 1000)
        return
      }
      setTimeout(() => {
        this.sendAnswer(matchQuestion.answer)
      }, 1000)
    },
    sendQuestion(q, answer) {
      const message = {
        me: true,
        body: q,
        id: this.messages.length + 1,
        author: 'User',
      }
      this.messages.push(message)
      this.message = ''
      setTimeout(() => {
        this.sendAnswer(answer)
      }, 1000)
    },
    sendAnswer(answer) {
      const message = {
        me: false,
        body: answer,
        id: this.messages.length + 1,
        author: 'Operator',
      }
      this.messages.push(message)
      clearTimeout(this._timerId)

      this._timerId = setTimeout(() => {
        this.onReceiveAnswer()
      }, 20000)
    },
    onReceiveAnswer() {
      const text = 'Did it work for you?'
      const yes = this.onHelped
      const no = this.onDidntHelp
      const newMessage = this.generateNewMessage(text)
      this.messages.push({
        ...newMessage,
        actions: {
          yes,
          no,
        },
      })
    },
    onHelped() {
      const text = 'Thank you for contacting us! We are always here to help 😊'
      const newMessage = this.generateNewMessage(text)
      this.messages.push(newMessage)
    },
    onDidntHelp() {
      const text =
        'Try rephrasing your question, there may be an answer. Or would you like a personal assistant?'
      const yes = this.onContactAssistent
      const no = this.onDenyContactAssistent
      const newMessage = this.generateNewMessage(text)
      this.messages.push({
        ...newMessage,
        actions: {
          yes,
          no,
        },
      })
    },
    onContactAssistent() {
      const text =
        'It may take up to 24 hours for an assistant to respond 😬. Do you want to write?'

      const newMessage = this.generateNewMessage(text)
      this.messages.push({
        ...newMessage,
        actions: {
          yes: () => {
            this.$router.push({ path: '/pages/contactus' })
          },
          no: this.onDenyContactAssistent,
        },
      })
    },
    onContactAssistentConfirm() {
      const text = 'Please click here 👉'
      const newMessage = this.generateNewMessage(text)
      this.messages.push(newMessage)
    },
    onDenyContactAssistent() {
      const text =
        'Try rephrasing your question, there may be an answer 🤗'
      const newMessage = this.generateNewMessage(text)
      this.messages.push(newMessage)
    },
    sendWelcomeMessage() {
      const newMessage = this.generateNewMessage(
        'Hello, Mr. Guest! How can I help you? 😊'
      )
      this.messages.push(newMessage)
    },
    generateNewMessage(body) {
      const newMessage = {
        body,
        id: this.messages.length + 1,
        me: false,
        author: 'Operator',
      }
      return newMessage
    },
    addQuestionToFAQ(question) {
      this.createFAQ(question)
      setTimeout(() => {
        this.sendAppreciation()
      }, 1000)
      setTimeout(() => {
        this.askForUrgency()
      }, 2000)
    },
    sendAppreciation() {
      const text =
        'Thank you so much for helping us to be better. Your question has been added to the collection and will be answered soon 🤗'
      const newMessage = this.generateNewMessage(text)
      this.messages.push({
        ...newMessage,
      })
    },
    askForUrgency() {
      const text =
        'If you have an urgent question, you can contact us via the contact section 👉'
      const newMessage = this.generateNewMessage(text)
      this.messages.push({
        ...newMessage,
        actions: {
          yes: () => {
            this.$router.push({ path: '/pages/contactus' })
          },
          confirmText: 'Contacts',
        },
      })
    },
    handleResize() {
      this.screenWidth = window.innerWidth
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    if (window.innerWidth < 980) {
      this.accordeonPanel = 0
    }
  },
  created() {
    if (window.innerWidth < 980) {
      this.accordeonPanel = 0
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="scss" scoped>
.chat-window {
  height: 350px;
  max-width: 300px;
  overflow-y: scroll;
}
@media (max-width: 979px) {
  .chat-window {
    width: 100% !important;
    max-width: 100% !important;
    max-height: calc(100vh - 200px) !important;
    height: calc(100vh - 200px) !important;
  }
  .chat-bot form {
    /* position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding-top: auto; */
  }
  .chat-bot {
    width: 100vw !important;
    height: 100vh;
  }
  .chat-bot__content {
    height: auto;
  }
  .chat-input {
    margin-top: auto;
    height: auto;
  }
}
.inputchatbot {
  margin-left: 40px;
    background: #f0f0f0;
    padding: 0 15px 15px 15px;
    border-radius: 15px;
    max-width: 200px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.chat-window::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-window {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.message {
  font-size: 14px;
}
.message__body {
  border-radius: 10px;
  background-color: #cecece;
  max-width: 200px;
}
.message__actions {
  &:hover {
    background: linear-gradient(to right, #2fd34f, #1bb43a);
    border: none !important;
    color: #fff !important;
  }
}
.question-suggestions {
  max-height: 100px;
  max-width: 300px;
  overflow: scroll;
  height: fit-content;
  transform: translateY(-100%);
  left: 0;
  width: 100%;
  background-color: #fff;
}
</style>
<style lang="scss">
.close-chat::before {
  color: #fff !important;
}
.chat-bot__content {
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
</style>
